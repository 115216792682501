import { makeStyles } from '@mui/styles';

const myMakeStyle = (defaultStyle = (theme) => { }, rtl = (theme) => { }) => {

    return makeStyles(theme => {
        let style = defaultStyle(theme);
        if (theme.direction === 'rtl') {
            style = {
                ...style,
                ...rtl(theme)
            }
        }

        return style;
    })
}

export default myMakeStyle;