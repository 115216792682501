import React from 'react';
import { DataService } from 'utils/DataService/axios';
import { toastError, toastSuccess } from 'components/Toasts/Toasts';

export const LoginByPassword = (email, password) => {
    return new Promise(async (resolve, reject) => {
        const data = { email, password };
        try {
            const res = await DataService.post(`/admin/auth`, data);
            const response = res.data;
            if (response.status === 200) {
                toastSuccess('Welcome !');
                resolve(response);
            } else {
                toastError(response?.message);
                reject(response);
            }
        } catch (err) {
            reject(err);
        }
    });
};

export const requestForOTP = email => {
    return new Promise(async (resolve, reject) => {
        const data = { username: email, isPanel: true };
        try {
            const res = await DataService.post(`/auth/sendOTP`, data);
            const response = res.data;
            if (response.status === 200) {
                toastSuccess(response.result.msg);
                resolve(response);
            } else {
                reject(response);
            }
        } catch (err) {
            reject(err);
        }
    });
};

export const OTPCheck = (email, OTPCode) => {
    return new Promise(async (resolve, reject) => {
        const data = { username: email, OTPCode };
        try {
            const res = await DataService.post(`/auth/otp`, data);
            const response = res.data;
            if (response.status === 200) {
                toastSuccess('Welcome');
                resolve(response);
            } else {
                reject(response);
            }
        } catch (err) {
            reject(err);
        }
    });
};

export const logout = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.get(`/admin/auth/logout`);
            const response = res;
            if (response.status === 200) {
                toastSuccess('logout Success');
                resolve(response);
            } else {
                reject(response);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const CheckIsLoggdIn = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.get(`/admin/auth/checkLogin`);
            const response = res;
            if (response.status === 200) {
                resolve(response);
            } else {
                reject(response);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const EmailCheck = email => {
    return new Promise(async (resolve, reject) => {
        const data = { isPanel: true, username: email, hasPassword: true };
        try {
            const res = await DataService.post(`/auth`, data);
            const response = res.data;
            if (response.status === 200) {
                toastSuccess('identified !');
                resolve(response);
            } else {
                reject(response);
            }
        } catch (err) {
            reject(err);
        }
    });
};

export const PasswordCheck = (email, password) => {
    return new Promise(async (resolve, reject) => {
        const data = { username: email, password };
        try {
            const res = await DataService.post(`/auth`, data);
            const response = res.data;
            if (response.status === 200) {
                toastSuccess(response.result.msg);
                resolve(response);
            } else {
                reject(response);
            }
        } catch (err) {
            reject(err);
        }
    });
};
