import myMakeStyle from 'theme/myMakestyle';
import { colors } from '@mui/material';

const ltrStyle = (theme) => ({
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0,
    },
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },
    button: {
        fontWeight: "bold",
        color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
    },
    buttonLeaf: {
        color: colors.blueGrey[800],
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightRegular,
        '&.depth-0': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
    icon: {
        color: theme.palette.icon,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
    expandIcon: {
        marginRight: 'auto',
        height: 16,
        width: 16,
    },
    label: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
            color: theme.palette.primary.main,
        },
    },
})

const rtlStyle = (theme) => ({
    icon: {
        ...ltrStyle(theme).icon,
        marginRight: theme.spacing(0),
        marginLeft: theme.spacing(1),
    }
})

export default myMakeStyle(ltrStyle, rtlStyle);