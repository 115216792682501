import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, CircularProgress, Chip } from '@mui/material';
import { SearchRelatedContentAsync } from 'api/Search/searchApi';
import PropTypes from 'prop-types';
import { GetContentList } from 'api/Content/contentApi';
import RequiredInput from 'myComponents/Required/RequiredInput';
import NoOptionFound from './components/NoOptionFound';

const SearchContents = React.forwardRef((props, ref) => {
    /* ****************************************************** Props ***************************************************** */
    const {
        onChange,
        multiple = false,
        helperTextOfAutoComplete,
        withOutTags,
        disableBackspace,
        error,
        freeSolo,
        label,
        disabled,
        defaultValue,
        allTitle,
        isParent,
        languageId,
        required,
        disableClearable,
        tt,
        ...rest
    } = props;

    /* ***************************************************** States ***************************************************** */
    const [contentsOptions, setContentsOptions] = useState([]);
    const [showedOptions, setShowedOptions] = useState([]);
    const [contentLoading, setContentLoading] = useState(false);
    const [contentsUserSearch, setContentsUserSearch] = useState('');

    let searchBasedOn = { title: contentsUserSearch, minimumData: true };
    if (allTitle) {
        searchBasedOn = { allTitle: contentsUserSearch, languageId, minimumData: true };
    }
    if (isParent === true) {
        searchBasedOn.isParent = true;
    } else if (isParent === false) {
        searchBasedOn.isParent = false;
    }

    /* ************************************************** Side Effects ************************************************** */
    useEffect(() => {
        if (contentsUserSearch.length > 2) {
            setContentLoading(true);
            GetContentList(searchBasedOn)
                .then(res => {
                    setContentLoading(false);
                    setContentsOptions(res.rows);
                })
                .catch(err => {
                    setContentLoading(false);
                    setContentsOptions([]);
                });
        } else if (!contentsUserSearch.length && tt) {
            setContentsOptions([]);
        } else if (defaultValue) {
            setContentsOptions([defaultValue]);
        } else {
            setContentsOptions([]);
        }
    }, [contentsUserSearch]);

    useEffect(() => {
        if (multiple) {
            const existIds = rest.value.map(item => item?.id);
            if (contentsOptions.length && contentsUserSearch.length > 0) {
                const filteredData = contentsOptions.filter(item => !existIds.includes(item.id));
                setShowedOptions(filteredData);
            }
        } else {
            setShowedOptions(contentsOptions);
        }
    }, [contentsOptions]);

    return (
        <Autocomplete
            {...rest}
            options={showedOptions}
            multiple={multiple}
            filterSelectedOptions
            disableClearable={disableClearable}
            freeSolo={freeSolo}
            ref={ref}
            defaultValue={defaultValue || null}
            id={`search-contents_${Math.random()}`}
            isOptionEqualToValue={(option, value) => option?.title === value?.title}
            getOptionLabel={option => option?.title ?? ''}
            loading={Boolean(contentLoading)}
            filterOptions={x => x}
            onChange={onChange}
            inputValue={contentsUserSearch}
            disabled={disabled ? true : false}
            noOptionsText={
                <NoOptionFound
                    conditionToShowNoOptionFound={
                        !!contentsUserSearch.length && !contentsOptions.length
                    }
                    autocompleteName="content"
                />
            }
            renderTags={
                withOutTags
                    ? () => null
                    : (tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip label={option.title} {...getTagProps({ index })} />
                        ))
            }
            onInputChange={(_, newText) => setContentsUserSearch(newText)}
            renderInput={params => (
                <TextField
                    {...params}
                    error={error}
                    disabled={disabled ? true : false}
                    margin="dense"
                    label={label}
                    placeholder="Search"
                    onKeyDown={event => {
                        if (disableBackspace && event.key === 'Backspace') {
                            event.stopPropagation();
                        } else {
                            return;
                        }
                    }}
                    helperText={helperTextOfAutoComplete}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {contentLoading ? (
                                    <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                                {required && <RequiredInput />}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
});

SearchContents.propTypes = {
    existedContents: PropTypes.array,
    onChange: PropTypes.func,
    multiple: PropTypes.bool,
    helperTextOfAutoComplete: PropTypes.string,
    error: PropTypes.bool,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.array,
    withOutTags: PropTypes.bool,
    disableBackspace: PropTypes.bool,
};

SearchContents.defaultProps = {
    onChange: () => { },
    multiple: false,
    helperTextOfAutoComplete: '',
    withOutTags: false,
    label: '',
    disabled: false,
    defaultValue: [],
};

export default SearchContents;
