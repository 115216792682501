import axios from 'axios';
import { toastError } from 'components/Toasts/Toasts';
import { API, authHeader } from './api';

const API_ENDPOINT = API;

const client = axios.create({
    baseURL: API_ENDPOINT,
    // withCredentials: true,
});

let headers = () => ({
    ...authHeader(),
    'Content-Type': 'application/json',
});

class DataService {
    static get(url = process.env.REACT_APP_DEV_BASE_URL, cancelToken = null) {
        try {
            return client({
                method: 'GET',
                url,
                headers: headers(),
                cancelToken,
            });
        } catch (err) {
            console.log(err);
        }
    }

    static post(
        path = process.env.REACT_APP_DEV_BASE_URL,
        data,
        cancelToken = null,
        optionalHeader = {},
        progress = () => { }
    ) {
        try {
            return client({
                headers: { ...headers(), ...optionalHeader },
                method: 'POST',
                url: path,
                data,
                cancelToken,
                onUploadProgress: (progressEvent) => {
                    const percentage = (progressEvent.loaded * 100) / progressEvent.total;
                    const percent = +percentage.toFixed(2);
                    console.log(percent)
                    progress(percent)
                }
            });
        } catch (error) {
            throw error
        }
    }

    static patch(path = process.env.REACT_APP_DEV_BASE_URL, data = {}, cancelToken = null) {
        return client({
            headers: headers(),
            method: 'PATCH',
            url: path,
            data: JSON.stringify(data),
            cancelToken,
        });
    }

    static delete(path = process.env.REACT_APP_DEV_BASE_URL, data = {}, cancelToken = null) {
        return client({
            headers: headers(),
            method: 'DELETE',
            url: path,
            data: JSON.stringify(data),
            cancelToken,
        });
    }

    static put(path = process.env.REACT_APP_DEV_BASE_URL, data = {}, cancelToken = null) {
        return client({
            headers: headers(),
            method: 'PUT',
            url: path,
            data: JSON.stringify(data),
            cancelToken,
        });
    }
}

/**
 * axios interceptors runs before and after a request, letting the developer modify req,req more
 * For more details on axios interceptor see https://github.com/axios/axios#interceptors
 */
client.interceptors.request.use(config => {
    // do something before executing the request
    // For example tag along the bearer access token to request header or set a cookie
    const requestConfig = config;
    // const { headers } = config;
    // requestConfig.headers = {
    //   ...headers,
    //   Authorization: `Bearer ${getItem('access_token')}`
    // };

    return requestConfig;
});

client.interceptors.response.use(
    response => response,
    error => {
        /**
         * Do something in case the response returns an error code [3**, 4**, 5**] etc
         * For example, on token expiration retrieve a new access token, retry a failed request etc
         */
        const { response } = error;
        const originalRequest = error.config;
        if (response) {
            if (response.status !== 401 && response.data.message) {
                let errors;
                if (Array.isArray(response.data.message)) {
                    errors = response.data.message;
                } else {
                    errors = [response.data.message];
                }
                if (response.data.status !== 404) {
                    errors.map(item => toastError(`${response.data.status}: ${item}`));
                }
            }
            return response;
        }
        else {
            return error
        }
    },
);
export { DataService };

const instant = axios.create();

export default instant;
