/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import TheatersOutlinedIcon from '@mui/icons-material/TheatersOutlined';
import LayersIcon from '@mui/icons-material/Layers';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

import { createUrl, findUrl } from 'routes/routeHelper';
import {
    AdminPanelSettings,
    Collections,
    Group,
    Settings,
} from '@mui/icons-material';
import { t } from 'i18next';

export default {
    pages: [
        {
            title: 'Dashboard',
            href: '/',
            icon: DashboardIcon,
            permissions: [],
        },
        {
            service: ["LIVE"],
            title: 'Live',
            href: '/live',
            icon: DashboardIcon,
            permissions: ["live_management", "Live_list"],
        },
        {
            title: 'Manage Content',
            href: '/management',
            icon: TheatersOutlinedIcon,
            children: [
                {
                    title: 'Content Library',
                    href: findUrl('contentList'),
                    permissions: ["content_list", "content_management"],
                },
                {
                    title: 'Video Library',
                    href: findUrl('videoList'),
                    permissions: ["video_list", "video_management"],
                },
                {
                    title: 'Manage Cast/Crew',
                    href: '/management',
                    children: [
                        {
                            title: 'Cast/Crew Library',
                            href: findUrl('castAndCrewList'),
                            permissions: ["castCrew_list", "castCrew_management"],
                        },
                        {
                            title: 'Roles',
                            href: findUrl('castAndCrewRolesList'),
                            permissions: ["castCrewRole_list", "castCrewRole_management"],
                        },
                    ],
                },
                {
                    title: 'Manage Metadata',
                    href: '/management',
                    children: [
                        {
                            title: 'Video Category',
                            href: findUrl('videoCategory'),
                            permissions: ["category_list", "category_management"],
                        },
                        {
                            title: 'Video Genre',
                            href: findUrl('videoGenre'),
                            permissions: ["genre_list", "genre_management"],
                        },
                    ],
                },
                {
                    title: 'Manage Comments',
                    href: '/management',
                    children: [
                        {
                            title: 'Comments',
                            href: findUrl('commentsList'),
                            permissions: ["comments_list", "comments_management"],
                        },
                        {
                            title: 'Reported Comments',
                            href: findUrl('reportedCommentsList'),
                            permissions: ["comments_list", "comments_management"],
                        },
                    ],
                },
            ],
        },
        {
            service: ["GALLERY"],
            title: 'Gallery',
            href: '/gallery',
            icon: Collections,
            children: [
                {
                    title: 'Image & Teasers',
                    href: findUrl('galleryList'),
                    permissions: [],
                },
                {
                    title: 'Avatars',
                    href: findUrl('avatarsList'),
                    permissions: ["avatar_management"],
                },
            ],
        },
        {
            title: 'Pages',
            href: '/pages',
            icon: LayersIcon,
            children: [
                {
                    title: 'Main Pages',
                    href: createUrl('pageBuilderLanguage', ['mainPage', 0]),
                    permissions: ["page_builder_list", "page_builder_management"],
                },
            ],
        },
        {
            service: ["CVG", "ADS", "SUBSCRIPTION"],
            title: 'Monetization',
            href: '/monetization',
            icon: LocalAtmOutlinedIcon,
            children: [
                // {
                //     title: 'Payment Gateway',
                //     href: findUrl('paymentGateway'),
                //     permissions: ["payment_gateway_list", "payment_gateway_management"],
                // },
                {
                    service: ["SUBSCRIPTION"],
                    title: 'Subscriptions',
                    href: '/subscription',
                    children: [
                        {
                            title: 'Subscriptions list',
                            href: findUrl('subscriptionList'),
                            permissions: ["subscription_list", "subscription_management"],
                        },
                        {
                            title: 'Subscribers',
                            href: findUrl('subscribersList'),
                            permissions: ["subscriber_list", "subscriber_management"],
                        },
                    ],
                },
                {
                    service: ["CVG"],
                    title: 'Coupons',
                    href: findUrl('couponsList'),
                    permissions: ["coupon_list", "coupon_management"],
                },
                // {
                //     title: 'Vouchers',
                //     href: findUrl('vouchersList'),
                //     permissions: ["voucher_list", "voucher_management"],
                // },
                // {
                //     title: 'Gifts',
                //     href: findUrl('giftsList'),
                //     permissions: ["gifts_list", "gifts_management"],
                // },
                {
                    service: ["ADS"],
                    title: 'Ads',
                    href: findUrl('adsList'),
                    permissions: ["ads_list", "ads_management"],
                },
            ],
        },
        {
            title: 'Users',
            href: '/users',
            icon: Group,
            children: [
                {
                    title: 'Users list',
                    href: findUrl('usersList'),
                    permissions: ["user_list", "user_management"],
                },
            ],
        },
        {
            title: 'Admin',
            href: '/admin',
            icon: AdminPanelSettings,
            children: [
                {
                    title: 'Admins management',
                    href: findUrl('adminsList'),
                    permissions: ["admin_list", "admin_management"],
                },
                {
                    title: 'Permission Groups',
                    href: findUrl('permissionGroupsList'),
                    permissions: ["admin_list", "admin_management"],
                },
                {
                    title: 'Static Permissions',
                    href: findUrl('staticPermissionsList'),
                    permissions: ["admin_list", "admin_management"],
                },
            ],
        },
        // {
        //     title: 'Analaytics',
        //     href: '/analaytics',
        //     icon: Analytics,
        //     children: [
        //         {
        //             title: 'Users',
        //             href: findUrl('usersAnalaytics'),
        //             permissions: ["reports"],
        //         },
        //     ],
        // },

        {
            service: ["NOTIFICATION"],
            title: 'Notifications',
            href: findUrl('notificationsList'),
            icon: NotificationsActiveIcon,
            permissions: ['notification'],
        },
        {
            title: 'Settings',
            href: '/settings',
            icon: Settings,
            children: [
                {
                    title: 'Languages',
                    href: findUrl('languages'),
                    permissions: ["setting_list", "setting_management"],
                },
                {
                    title: 'Age Categories',
                    href: findUrl('ageCategories'),
                    permissions: ["setting_list", "setting_management"],
                },
                {
                    title: 'Sign in Options',
                    href: findUrl('oAuth'),
                    permissions: ["setting_list", "setting_management"],
                },
                {
                    title: 'Companies',
                    href: findUrl('companies'),
                    permissions: ["setting_list", "setting_management"],
                },
                {
                    title: 'Site setting',
                    href: findUrl('siteSetting'),
                    permissions: ["setting_list", "setting_management"],
                },
            ],
        },
    ],
}