import { InputAdornment, Typography } from '@mui/material'
import React from 'react'

const RequiredInput = () => {
  return (
    <InputAdornment position="end">
        <Typography variant="caption" color="error" sx={{fontSize: "18px", lineHeight: 0}}>*</Typography>
    </InputAdornment>
  )
}

export default RequiredInput