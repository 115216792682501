import { DataService } from 'utils/DataService/axios';
import { toastError, toastSuccess } from 'components/Toasts/Toasts';
import { API } from 'utils/DataService/api';

export const GetVideoList = (optionFilters, cancelToken) => {
    const { page = 1, take = 10, search } = optionFilters

    let filters = ``;
    if (search.length > 0) {
        filters = `search=${search}&`;
    }

    if (page !== null || page !== undefined) {
        filters += `page=${page}&`;
    }

    if (take !== null || take !== undefined) {
        filters += `take=${take}&`;
    }

    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.get(`/admin/content/videoFiles/list?${filters}`, cancelToken);
            const response = res;
            if (response.status === 200) {
                resolve(response.data.result);
            } else if (response.hasOwnProperty('error')) {
                response.map(err => toastError(err));
                reject(response);
            } else {
                reject(response);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const postFileBeforeUpload = data => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.post(`/admin/content/uploads/videoData`, data);
            const response = res;
            if (response.status === 200) {
                toastSuccess('File Added !');
                resolve(response.data.result);
            } else if (response.hasOwnProperty('error')) {
                response.map(err => toastError(err));
                reject(response);
            } else if (response.status === 400) {
                reject(response);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const uploadEndpoint = `${API}/admin/content/uploads/video`;

export const checkVideoUploadComplete = tusId => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.get(`/admin/content/uploads/checkCompleteUpload/${tusId}`);
            const response = res.data;
            if (res.status === 200) {
                toastSuccess('Upload Completed');
                resolve(response);
            } else {
                reject(response);
            }
        } catch (error) {
            reject(error);
        }
    });
};


export const checkAudioAndSubtitle = tusId => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.get(`/admin/content/uploads/validateUploadAudioSubtitle/${tusId}`);
            const response = res.data;
            if (res.status === 200) {
                toastSuccess('Upload Completed');
                resolve(response);
            } else {
                reject(response);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export const editAudioAndSubtitle = (fileId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.put(`/admin/content/uploads/updateAudioSubtitle/${fileId}`, data)
            if (res.status === 200) {
                resolve(res.data)
            } else {
                reject(res)
            }
        } catch (error) {
            reject(error)
        }
    })
}

export const removeAudioAndSubtitle = (fileId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.delete(`/admin/content/uploads/deleteAudioSubtitle/${fileId}`)
            if (res.status === 200) {
                resolve(res.data)
            } else {
                reject(res)
            }
        } catch (error) {
            reject(error)
        }
    })
}

export const updateVideoSourceData_API = (videoSourceId, data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.put(`/admin/content/uploads/update/${videoSourceId}`, data)
            if (res.status === 200) {
                resolve(res.data)
            } else {
                reject(res)
            }
        }
        catch (error) {
            reject(error)
        }
    })
}

export const deleteVideoSource_API = (videoSourceId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.delete(`/admin/content/uploads/videoFile/${videoSourceId}`)
            if (res.status === 200) {
                resolve(res.data)
            } else {
                reject(res)
            }
        }
        catch (error) {
            reject(error)
        }
    })
}

export const updateVideoEncodeStatus_API = (videoSourceId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.patch(`/admin/content/updateVideoEncodeStatus/${videoSourceId}`)
            if (res.status === 200) {
                resolve(res.data)
            } else {
                reject(res)
            }
        }
        catch (error) {
            reject(error)
        }
    })
}

export const getVideoFileStreamURL_API = (videoSourceId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.get(`/admin/content/videoFiles/getStream/${videoSourceId}`)
            if (res.status === 200) {
                resolve(res.data)
            } else {
                reject(res)
            }
        }
        catch (error) {
            reject(error)
        }
    })
}