import { DataService } from 'utils/DataService/axios';

const GetVODConfiguration_API = cancelToken => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await DataService.get('/admin/configuration', cancelToken);

            if (res.status === 200) {
                resolve(res.data.result);
            } else {
                reject(res);
            }
        } catch (error) {
            reject(error);
        }
    });
};

export { GetVODConfiguration_API };
