import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import uuid from 'uuid/v1';
import { useDropzone } from 'react-dropzone';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@mui/styles';
import {
    Button,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Tooltip,
    colors,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreIcon from '@mui/icons-material/MoreVert';

import bytesToSize from 'utils/bytesToSize';

const useStyles = makeStyles(theme => ({
    root: {},
    dropZone: {
        border: `1px dashed ${theme.palette.divider}`,
        padding: theme.spacing(6),
        outline: 'none',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: colors.grey[50],
            opacity: 0.5,
            cursor: 'pointer',
        },
    },
    dragActive: {
        backgroundColor: colors.grey[50],
        opacity: 0.5,
    },
    image: {
        width: 130,
    },
    info: {
        marginTop: theme.spacing(1),
    },
    list: {
        maxHeight: 320,
    },
    actions: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));

const FilesDropzone = props => {
    const {
        className,
        uploadFile = () => { },
        ...rest
    } = props;

    const classes = useStyles();

    const [files, setFiles] = useState([]);

    const handleDrop = useCallback(acceptedFiles => {
        uploadFile(acceptedFiles);
        // setFiles(files => [...files].concat(acceptedFiles));
    }, []);

    const handelClickUpload = () => {
        uploadFile(files)
    }

    const handleRemoveAll = () => {
        setFiles([]);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: handleDrop,
        maxFiles: 1,
        multiple: false,
        accept: "image/*,video/*"
    });

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <div
                className={clsx({
                    [classes.dropZone]: true,
                    [classes.dragActive]: isDragActive,
                })}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <div>
                    <img alt="Select file" className={classes.image} src="/images/undraw_add_file2_gvbb.svg" />
                </div>
                <div>
                    <Typography gutterBottom variant="h3">
                        Select files
                    </Typography>
                    <Typography className={classes.info} color="textSecondary" variant="body1">
                        Drop files here or click <Link underline="always">browse</Link> thorough your machine
                    </Typography>
                </div>
            </div>
            {/* {files.length > 0 && (
                <Fragment>
                    <PerfectScrollbar options={{ suppressScrollX: true }}>
                        <List className={classes.list}>
                            {files.map((file, i) => (
                                <ListItem divider={i < files.length - 1} key={uuid()}>
                                    <ListItemIcon>
                                        <FileCopyIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={file.name}
                                        primaryTypographyProps={{ variant: 'h5' }}
                                        secondary={bytesToSize(file.size)}
                                    />
                                    <Tooltip title="More options">
                                        <IconButton edge="end" size="large">
                                            <MoreIcon />
                                        </IconButton>
                                    </Tooltip>
                                </ListItem>
                            ))}
                        </List>
                    </PerfectScrollbar>
                    <div className={classes.actions}>
                        <Button onClick={handleRemoveAll} size="small">
                            Remove all
                        </Button>
                        <Button color="secondary" size="small" variant="contained" onClick={handelClickUpload}>
                            Upload files
                        </Button>
                    </div>
                </Fragment>
            )} */}
        </div>
    );
};

export default FilesDropzone;
