/* eslint-disable react/no-multi-comp */
import React from 'react';
import { matchPath } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { List, Typography } from '@mui/material';
import { t } from 'i18next';
import useRouter from 'utils/useRouter';
import { NavigationListItem } from './components';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(3),
    },
}));

const NavigationList = props => {
    const { pages, ...rest } = props;
    return (
        <List>
            {pages.reduce((items, page) => reduceChildRoutes({ items, page, ...rest }), [])}
        </List>
    );
};

NavigationList.propTypes = {
    depth: PropTypes.number,
    pages: PropTypes.array,
};

const reduceChildRoutes = props => {
    const { router, items, page, depth } = props;

    if (page.children) {
        const open = matchPath(router?.location?.pathname, {
            path: page.href,
            exact: false,
        });

        items.push(
            <NavigationListItem
                depth={depth}
                icon={page.icon}
                key={page.title}
                label={page.label}
                open={Boolean(open)}
                title={t(page.title)}
            >
                <NavigationList depth={depth + 1} pages={page.children} router={router} />
            </NavigationListItem>,
        );
    } else {
        items.push(
            <NavigationListItem
                depth={depth}
                href={page.href}
                icon={page.icon}
                key={page.title}
                label={page.label}
                title={t(page.title)}
            />,
        );
    }

    return items;
};

const Navigation = props => {
    const { title, pages, className, permissions, isFullAccess, component: Component, ...rest } = props;

    const hasServices = useSelector(state => state.generalData.vodConfiguration.services);
    const classes = useStyles();
    const router = useRouter();

    let validPage = [];
    if (isFullAccess) {
        validPage = pages;
    }
    else {
        validPage = validateAccessToPageNav(permissions, pages)
    }

    // hasServices
    if (hasServices) {
        validPage = validPage.filter(item => {
            if (!item?.service || item?.service?.length === 0) {
                return true;
            }
            return item.service.map(key => `HAS_${key}_SERVICE`).some(it => hasServices.includes(it));
        }).map(item => {
            if (item.children) {
                item.children = item.children.filter(it => {
                    if (!it?.service || it?.service?.length === 0) {
                        return true;
                    }

                    return it.service.map(key => `HAS_${key}_SERVICE`).some(i => hasServices.includes(i));
                })
            }

            return item;
        })
    }

    return (
        <Component {...rest} className={clsx(classes.root, className)}>
            {title && <Typography variant="overline">{t(title)}</Typography>}
            <NavigationList depth={0} pages={validPage} router={router} />
        </Component>
    );
};

const validateAccessToPageNav = (permissions, pages, hasServices = []) => {
    let newPages = [];
    console.log(55555555, pages)
    pages.forEach(page => {
        if (page.children) {
            page.children = validateAccessToPageNav(permissions, page.children);
            if (page.children.length > 0) {
                newPages.push(page);
            }
        }
        else {
            if (page.permissions.length === 0 || page.permissions.some(per => permissions.includes(per))) {
                newPages.push(page);
            }
        }
    })

    return newPages;
}

Navigation.propTypes = {
    className: PropTypes.string,
    component: PropTypes.any,
    pages: PropTypes.array.isRequired,
    title: PropTypes.string,
};

Navigation.defaultProps = {
    component: 'nav',
};

export default Navigation;
