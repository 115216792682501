import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Drawer, Paper } from '@mui/material';
import { Hidden } from '@mui/material';
import NavBarStyle from './NavBar.style';


import useRouter from 'utils/useRouter';
import { Navigation } from 'components';
import navigationConfig from './navigationConfig';

const NavBar = props => {
    const { openMobile, onMobileClose, className, ...rest } = props;

    const classes = NavBarStyle();
    const router = useRouter();
    const auth = useSelector(state => state.auth);

    useEffect(() => {
        if (openMobile) {
            onMobileClose && onMobileClose();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router?.location?.pathname]);

    const navbarContent = (
        <div className={classes.content}>
            <nav className={classes.navigation}>
                <Navigation
                    component="div"
                    isFullAccess={auth?.response?.isFullAccess}
                    permissions={auth.response.permissions}
                    pages={navigationConfig.pages}
                />
            </nav>
        </div>
    );

    return (
        <Fragment>
            <Hidden lgUp>
                <Drawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary">
                    <div {...rest} className={clsx(classes.root, className)}>
                        {navbarContent}
                    </div>
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Paper {...rest} className={clsx(classes.root, className)} elevation={1} square>
                    {navbarContent}
                </Paper>
            </Hidden>
        </Fragment>
    );
};

NavBar.propTypes = {
    className: PropTypes.string,
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

export default NavBar;
