/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import useRouter from 'utils/useRouter';

const NODE_ENV = process.env.NODE_ENV;
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID;

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        display: "flex",
        flexDirection: 'column',
        minHeight: '100%'
    },
    login: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(6, 2),
    },
}));

const Page = props => {
    const { title, children, loginPage, ...rest } = props;
    const router = useRouter();

    const classes = useStyles();

    useEffect(() => {
        if (NODE_ENV !== 'production') {
            return;
        }

        if (window.gtag) {
            window.gtag('config', GA_MEASUREMENT_ID, {
                page_path: router?.location?.pathname,
                page_name: title,
            });
        }
    }, [title, router]);

    return (
        <div {...rest} className={loginPage ? classes.login : classes.root}>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {children}
        </div>
    );
};

Page.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
};

export default Page;
